import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    IconButton,
    Typography,
    Checkbox,
    Button,
    Divider,
    Stack,
    Avatar,
    Icon,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineCamera, AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import selectOptionsIdName from "../../../helpers/selectOptions";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import Axios from "../../../api/Axios";
import { useDropzone } from "react-dropzone";
import { LiaTimesSolid } from "react-icons/lia";

//500KB

//Validation
const validationSchema = Yup.object().shape({
    custom_field: Yup.array().of(
        Yup.object().shape({
            key: Yup.string().required("Name is required"),
            value: Yup.string().required("Value is required"),
        })
    ),
});

function CustomFieldsTabNew() {
    const [parentDesignationListData, setParentDesignationListData] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();
    console.log("BasicInfoTabNew - id:", id);

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, handleShowTabCondition } = useEmployeeContext();

    //React hook form
    const { handleSubmit, control, reset, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    });

    // package
    const { fields, append, remove } = useFieldArray({
        control,
        name: "custom_field",
    });

    useEffect(() => {
        if (!id) {
            append({
                key: "",
                value: "",
            });
        }
    }, [id, append]);

    //Submit form
    const handleSubmitForm = async (data) => {
        console.log("handleSubmitForm - data:", data);
        try {
            const res = await Axios.post(
                `/employee/custom-field`,
                {
                    employee_id: id ? id : basicEmployeeInfo?.id,
                    custom_field: data?.custom_field,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            console.log(res?.data?.employee_id, "employee res:", res);
            if (res?.data?.status === "success") {
                toast.success("Custom filed  data saved");
                handleShowTabCondition("login");
                // setTabValue((prev) => prev + 1);
                setErrorMessage(null);
                if (!id) {
                    navigate("/employees/manage");
                }
            } else if (res.data.success === "200") {
                toast.info("You don't have employee create custom filed info permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log("handleBasicSubmit - error:", error);
            setErrorMessage(error?.response?.data?.error);
        }
    };

    //Get all parent designation
    const getAllParentDesignation = async () => {
        try {
            const res = await Axios.get(
                "/designation/all",

                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res.data.status === "success") {
                const formatSelect = selectOptionsIdName(res?.data?.response_data);
                setParentDesignationListData(formatSelect);
            } else if (res.data.success === "200") {
                toast.info("You don't have all designation    permissions, contact admin");
                // navigate("/unauthorized", { replace: true });
            } else {
                console.log("error", res);
                setParentDesignationListData([]);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    //get position data
    const getCustomData = async () => {
        try {
            const response = await Axios.get(`/employee/custom-field/show/${id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });

            if (response?.data?.status === "success") {
                if (response?.data?.response_data?.length > 0) {
                    for (const item of response?.data?.response_data) {
                        append({
                            key: item?.key,
                            value: item?.value,
                        });
                    }
                } else {
                    append({
                        key: "",
                        value: "",
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    //Get all parent designation effect
    useEffect(() => {
        let checkParentDesignation = true;
        if (checkParentDesignation) {
            getAllParentDesignation();
        }
        return () => {
            checkParentDesignation = false;
        };
    }, []);

    useEffect(() => {
        if (id) {
            getCustomData();
        }
    }, [id]);

    return (
        <Box component={"form"} onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    {fields?.length > 0 ? (
                        fields?.map((row, index) => {
                            return (
                                <Grid container spacing={2} alignItems={"start"} sx={{ mb: 1 }} key={index}>
                                    <Grid item xs={fields?.length > 1 ? 11 : 12}>
                                        <Grid container spacing={2} alignItems={"start"} key={index}>
                                            <Grid item sm={6} xs={12}>
                                                <Controller
                                                    render={({ field, formState }) => (
                                                        <FormControl fullWidth variant="outlined">
                                                            <Typography
                                                                color={
                                                                    Object.keys(formState?.errors).length > 0
                                                                        ? !!formState.errors?.custom_field[index]?.key
                                                                            ? red[700]
                                                                            : ""
                                                                        : ""
                                                                }
                                                            >
                                                                Custom Field Name*
                                                            </Typography>
                                                            <TextField
                                                                {...field}
                                                                error={
                                                                    Object.keys(formState?.errors).length > 0
                                                                        ? !!formState?.errors?.custom_field[index]?.key
                                                                        : false
                                                                }
                                                                placeholder="Custom Field Name"
                                                                size="small"
                                                            />
                                                            {Object.keys(formState?.errors).length > 0 &&
                                                            !!formState?.errors?.custom_field[index]?.key ? (
                                                                <FormHelperText error>
                                                                    {formState?.errors?.custom_field[index]?.key?.message}
                                                                </FormHelperText>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </FormControl>
                                                    )}
                                                    name={`custom_field.${index}.key`}
                                                    control={control}
                                                    defaultValue=""
                                                />
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <Controller
                                                    render={({ field, formState }) => (
                                                        <FormControl fullWidth variant="outlined">
                                                            <Typography
                                                                color={
                                                                    Object.keys(formState?.errors).length > 0
                                                                        ? !!formState.errors?.custom_field[index]?.value
                                                                            ? red[700]
                                                                            : ""
                                                                        : ""
                                                                }
                                                            >
                                                                Custom Field Value*
                                                            </Typography>
                                                            <TextField
                                                                {...field}
                                                                error={
                                                                    Object.keys(formState?.errors).length > 0
                                                                        ? !!formState?.errors?.custom_field[index]?.value
                                                                        : false
                                                                }
                                                                placeholder="Custom Field Value"
                                                                size="small"
                                                            />
                                                            {Object.keys(formState?.errors).length > 0 &&
                                                            !!formState?.errors?.custom_field[index]?.value ? (
                                                                <FormHelperText error>
                                                                    {formState?.errors?.custom_field[index]?.value?.message}
                                                                </FormHelperText>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </FormControl>
                                                    )}
                                                    name={`custom_field.${index}.value`}
                                                    control={control}
                                                    defaultValue=""
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {fields?.length > 1 ? (
                                        <Grid item xs={1} sx={{ textAlign: "end" }}>
                                            <IconButton onClick={() => remove(index)} sx={{ mt: 3, border: "1px solid #d7d7d7" }}>
                                                <LiaTimesSolid size={20} />
                                            </IconButton>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            );
                        })
                    ) : (
                        <Typography variant="formTitle" align="center" width={1} mt={3}>
                            No list create yet{" "}
                        </Typography>
                    )}
                    <Box sx={{ mt: 4 }}>
                        <Button variant="outlined" startIcon={<AiOutlinePlus />} onClick={() => append()}>
                            Add
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <ErrorMessageShow handleError={errorMessage} />

            <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                <Button variant="contained" type={"submit"}>
                    Save
                </Button>
            </Stack>
        </Box>
    );
}

export default CustomFieldsTabNew;
