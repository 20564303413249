import { Paper, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

const ErrorBoundaryMessage = () => {
    return (
        <Paper elevation={0} sx={{ padding: "60px 0", background: red[400] }}>
            <Typography variant="h5" color={"white"} align="center">
                Something is not working properly.
            </Typography>
            <Typography variant="caption" color={"white"} align="center" sx={{ display: "block" }}>
                Please contact developers or check the console for more details on the error.
            </Typography>
        </Paper>
    );
};

export default ErrorBoundaryMessage;
