import {
    Avatar,
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { MdAlternateEmail } from "react-icons/md";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { renderFile } from "../../utils/utils";

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(40, "Password must not exceed 40 characters"),
    email: Yup.string().required("Email is required").email("Email is not valid"),
    codeNumber: Yup.string().required("Code Number  is required"),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const OTPInput = () => {
    //states
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [appName, setAppName] = useState("");
    const auth = useAuth();
    const navigate = useNavigate();
    //react-hook-form
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const location = useLocation();
    const onSubmit = async (data) => {
        console.log(data, location);
        try {
            const res = await Axios.post(`reset-password`, {
                email: data.email,
                password: data.password,
                otp: data.codeNumber,
                password_confirmation: data.confirmPassword,
            });
            if (res?.data?.status === "success") {
                toast.success("Password Changed Successfully");
                navigate("/login", { state: data });
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (auth.token) {
            navigate("/dashboard/home");
        } else {
            const token = localStorage.getItem("token");
            if (token !== null) {
                navigate("/dashboard/home");
            }
        }
    }, [localStorage.getItem("token")]);

    const onChange = (value, key) => {
        console.log(value, key);
    };

    useEffect(() => {
        if (location?.state?.email) {
            setValue("email", location.state.email);
        } else {
            window.location.href = "/login";
        }
    }, [location]);

    //Get Logo value
    const getConfigData = async () => {
        try {
            const res = await Axios.post(
                "/all/setting",
                {
                    name: ["setting_app_name", "company_logo", "company_favIcon"],
                },
                {
                    headers: {
                        Authorization: auth?.token ? "Bearer " + auth?.token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            console.log("getConfigData - res:", res);

            if (res?.data?.status === "success") {
                setAppName(res?.data?.response_data);
            } else if (res?.data?.status === "error") {
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        let isChecking = true;
        if (isChecking) {
            getConfigData();
        }
        return () => {
            isChecking = false;
        };
    }, []);

    return (
        <Box>
            <Helmet>
                <link rel="icon" href={appName?.company_favIcon ? renderFile(appName?.company_favIcon) : null} />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
                    <Paper elevation={4} sx={{ bgcolor: blue[200], height: "98vh" }}>
                        <Stack justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                            <img height="400px" src="/assets/images/login/forgot_pass.svg" alt="" />
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container>
                        <Stack justifyContent="center" alignItems="center" sx={{ height: "98vh" }}>
                            {appName?.company_logo ? (
                                <Avatar
                                    src={renderFile(appName?.company_logo)}
                                    alt="logo"
                                    variant="square"
                                    sx={{
                                        width: "auto",
                                        height: "auto",
                                        maxWidth: "300px",
                                        maxHeight: "80px",
                                    }}
                                />
                            ) : (
                                <Typography variant="h6" sx={{ fontSize: 16 }}>
                                    Please Upload image first
                                </Typography>
                            )}
                            <Box sx={{ maxWidth: 450 }}>
                                <Typography sx={{ my: 2 }} variant="h6" textAlign="center">
                                    Enter Code
                                </Typography>
                                <Typography sx={{ mb: 3 }} variant="body2" textAlign="center">
                                    A 4 digit code has been sent to your email address. Please enter the code below to continue.
                                </Typography>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Controller
                                        render={({ field, formState }) => (
                                            <FormControl fullWidth>
                                                <Typography color={!!formState.errors?.codeNumber ? red[700] : ""}>Code No.</Typography>
                                                <div className="otp_pin_area">
                                                    <OtpInput
                                                        {...field}
                                                        error={!!formState.errors?.codeNumber}
                                                        inputStyle={{
                                                            fontSize: "18px",
                                                            padding: "7px 5px",
                                                            width: "60px",
                                                            border: "1px solid #C4C4C4",
                                                            borderRadius: "4px",
                                                        }}
                                                        onChange={(value) => {
                                                            onChange(value, "code");
                                                            field.onChange(value);
                                                        }}
                                                        numInputs={4}
                                                        separator={<span style={{ margin: "0 10px" }}> </span>}
                                                    />
                                                </div>
                                                {!!formState.errors?.codeNumber ? (
                                                    <FormHelperText error>{errors?.codeNumber?.message}</FormHelperText>
                                                ) : (
                                                    ""
                                                )}
                                            </FormControl>
                                        )}
                                        name="codeNumber"
                                        control={control}
                                        defaultValue=""
                                    />
                                    <Controller
                                        render={({ field, formState }) => (
                                            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                                                <InputLabel htmlFor="login-email">Email</InputLabel>
                                                <OutlinedInput
                                                    disabled
                                                    id="login-email"
                                                    {...field}
                                                    error={!!formState.errors?.email}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton aria-label="toggle password visibility" edge="end">
                                                                <MdAlternateEmail />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Email"
                                                />
                                                {!!formState.errors?.email ? <FormHelperText error>{errors?.email?.message}</FormHelperText> : ""}
                                            </FormControl>
                                        )}
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                    />
                                    <Controller
                                        render={({ field, formState }) => (
                                            <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth>
                                                <InputLabel htmlFor="login-password">New Password</InputLabel>
                                                <OutlinedInput
                                                    id="login-password"
                                                    type={showPassword ? "text" : "password"}
                                                    {...field}
                                                    error={!!formState.errors?.password}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="New Password"
                                                />
                                                {!!formState.errors?.password ? (
                                                    <FormHelperText error>{errors?.password?.message}</FormHelperText>
                                                ) : (
                                                    ""
                                                )}
                                            </FormControl>
                                        )}
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                    />
                                    <Controller
                                        render={({ field, formState }) => (
                                            <FormControl sx={{ mt: 3 }} variant="outlined" fullWidth>
                                                <InputLabel htmlFor="login-password">Confirm Password</InputLabel>
                                                <OutlinedInput
                                                    id="login-password"
                                                    type={showPassword2 ? "text" : "password"}
                                                    {...field}
                                                    error={!!formState.errors?.confirmPassword}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle confirmPassword visibility"
                                                                onClick={() => setShowPassword2(!showPassword2)}
                                                                edge="end"
                                                            >
                                                                {showPassword2 ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Confirm Password"
                                                />
                                                {!!formState.errors?.confirmPassword ? (
                                                    <FormHelperText error>{errors?.confirmPassword?.message}</FormHelperText>
                                                ) : (
                                                    ""
                                                )}
                                            </FormControl>
                                        )}
                                        name="confirmPassword"
                                        control={control}
                                        defaultValue=""
                                    />
                                    {/*<Link to="/dashboard">*/}
                                    {/*    <Button variant="text" sx={{mb: 3, color: blue[600]}}>Guest Login</Button>*/}
                                    {/*</Link>*/}
                                    <Typography>
                                        Didn't get a code? Click <Link href={"/forgot-password"}>Here</Link>
                                    </Typography>
                                    <Button disabled={auth.loading} sx={{ mt: 3 }} size="large" fullWidth variant="contained" type="submit">
                                        Submit Code
                                    </Button>
                                </form>
                            </Box>
                        </Stack>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OTPInput;
