import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Axios from "../../api/Axios";
import { ROLENAME } from "../../helpers/commonUses";
import { useAuth } from "../useAuth";

function GetRolePermission() {
    const [storeRolePermissionPath, setStoreRolePermissionPath] = useState([]);
    console.log("render");

    //Context
    const { role, token } = useAuth();

    const roleName = ROLENAME;

    //Get Previous User Data
    const getPreviousRolePermissionList = async () => {
        try {
            const res = await Axios.get(
                "/get/self/role/permission",

                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            console.log("role permisson - res:", res);

            if (res.data.status === "success") {
                setStoreRolePermissionPath(res?.data?.response_data?.route_path);
                // setStoreRolePermissionPath(res?.data?.response_data?.route_path);
                // setShouldFetchData((prev)=>!prev)
            } else {
                setStoreRolePermissionPath([]);
            }
        } catch (error) {
            console.log("role  - error:", error);
            // setStorePermissionPath([]);
            if (error?.response?.status !== 422) {
                toast.warn("Something went wrong on permission ");
            }
        }
    };

    useEffect(() => {
        if (role !== roleName) {
            getPreviousRolePermissionList();
        }
    }, []);
    return { storeRolePermissionPath };
}

export default GetRolePermission;
