import { AppBar, Avatar, Box, Divider, IconButton, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { AiFillSetting, AiOutlineLogin, AiOutlineMenuUnfold } from "react-icons/ai";
import { IoMdNotifications } from "react-icons/io";
import { purple } from "@mui/material/colors";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Axios from "../api/Axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { renderFile } from "../utils/utils";

const Navbar = ({ open, toggleDrawer }) => {
    const [showLogo, setShowLogo] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const navigate = useNavigate();
    const auth = useAuth();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Context
    const { token } = useAuth();

    //Get Logo value
    const getConfigData = async () => {
        try {
            const res = await Axios.post(
                "/all/setting",
                {
                    name: ["company_logo", "company_favIcon", "setting_app_name"],
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res?.data?.status === "success") {
                setShowLogo(res?.data?.response_data);
            } else if (res?.data?.status === "error") {
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        let isChecking = true;
        if (isChecking) {
            getConfigData();
        }
        return () => {
            isChecking = false;
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Hello World</title>
                <link rel="icon" href={showLogo?.company_favIcon ? renderFile(showLogo?.company_favIcon) : "/logo192.png"} />
            </Helmet>

            <AppBar position="fixed" open={open} sx={{ background: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Helmet>
                    <title>{showLogo?.setting_app_name || ""} </title>

                    <link rel="icon" href={showLogo?.company_favIcon ? renderFile(showLogo?.company_favIcon) : null} />
                </Helmet>
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ mr: 2 }}>
                        <AiOutlineMenuUnfold color={"#0F3F62"} />
                    </IconButton>
                    <Stack sx={{ width: "100%" }} direction="row" justifyContent="space-between" alignItems="center">
                        {/*<Typography variant="h6" noWrap component="div" color="#0F3F62">*/}
                        {/*    HRMS*/}
                        {/*</Typography>*/}
                        <Stack direction={"row"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => navigate("/dashboard")}>
                            {showLogo?.company_logo ? (
                                <img src={renderFile(showLogo?.company_logo)} alt="Logo" style={{ maxHeight: "50px", maxWidth: "150px" }} />
                            ) : (
                                <Typography variant="h6" sx={{ fontSize: 16, color: "black" }}>
                                    Logo not found
                                </Typography>
                            )}
                        </Stack>

                        {/*<TextField*/}
                        {/*    id="outlined-start-adornment"*/}
                        {/*    placeholder="Search"*/}
                        {/*    sx={{m: 1, width: '55ch', bgcolor: "white", borderRadius: "5px"}}*/}
                        {/*    size="small"*/}
                        {/*    InputProps={{*/}
                        {/*        endAdornment: <InputAdornment position="end" sx={{width: "30px"}}>*/}
                        {/*            <IconButton variant="contained"><FiSearch color="#0F3F62"/></IconButton>*/}
                        {/*        </InputAdornment>,*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <Stack direction="row" alignItems="center">
                            <Stack sx={{ borderRight: "3px solid gray", paddingRight: "15px" }}>
                                <img style={{ maxHeight: "25px" }} src="/platform_logo.png" alt="platform_logo" />
                            </Stack>

                            {/*<IconButton variant="contained" onClick={onToggleFullscreen}><BiFullscreen size={24} color="#0F3F62"/></IconButton>*/}
                            {/*<IconButton variant="contained"><IoMdNotifications size={24} color="#0F3F62"/></IconButton>*/}
                            <Box>
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={menuOpen ? "account-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={menuOpen ? "true" : undefined}
                                    >
                                        {
                                            <Avatar
                                                src={renderFile(auth?.user?.image)}
                                                alt={auth?.user?.name}
                                                sx={{ width: 32, height: 32, bgcolor: purple[300] }}
                                            />
                                        }
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={menuOpen}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform: "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: "right", vertical: "top" }}
                            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        >
                            <MenuItem onClick={() => navigate("/profile")}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant={"subtitle2"}>{auth?.user?.email}</Typography>
                                    <Typography sx={{ ml: 1 }} variant={"subtitle2"}>
                                        {" "}
                                        ({auth?.role}){" "}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => navigate("/profile")}>
                                <Avatar /> My Profile
                            </MenuItem>
                            <MenuItem onClick={() => navigate("/account-settings")}>
                                <Avatar>
                                    <AiFillSetting />
                                </Avatar>{" "}
                                Account Settings
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={auth.signout}>
                                <Avatar>
                                    <AiOutlineLogin />
                                </Avatar>{" "}
                                Logout
                            </MenuItem>
                        </Menu>
                    </Stack>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
