import { useState } from "react";
import { Box, CssBaseline, Stack, Typography, styled } from "@mui/material";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import NoInternetConnection from "./NoInternetConnection";
import Page from "./Page";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryMessage from "./ErrorBoundaryMessage";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const Layout = () => {
    const [open, setOpen] = useState(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <CssBaseline />
            <Navbar open={open} toggleDrawer={toggleDrawer} />
            <Sidebar open={open} onClose={onClose} />
            <Main open={open} sx={{ background: "#F2F7FF", minHeight: "100vh" }}>
                <DrawerHeader />
                <Page>
                    <Box sx={{ ml: 32 }}>
                        <NoInternetConnection>
                            <ErrorBoundary fallback={<ErrorBoundaryMessage />}>
                                <Outlet />
                            </ErrorBoundary>
                        </NoInternetConnection>
                    </Box>
                </Page>
            </Main>
            <footer>
                <Stack alignItems={"center"}>
                    <Stack
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ padding: "2px 4px", borderRadius: "5px", margin: "5px 0" }}
                    >
                        <Typography variant="caption" style={{ textAlign: "left", display: "block" }}>
                            Powered by
                        </Typography>

                        <a href="https://goldeninfotech.com.bd/" target="_blank">
                            <img style={{ maxHeight: "30px", width: "auto" }} src="\logo192.png" alt="gdn-logo" />
                        </a>
                    </Stack>
                </Stack>
            </footer>
        </Box>
    );
};

export default Layout;
