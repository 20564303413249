import { Controller, useForm } from "react-hook-form";
import {
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import Axios from "../../../api/Axios";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function LeaveTabNew({ onSubmit }) {
    const [leaveType, setLeaveType] = useState([]);
    const [editLeaveType, setEditLeaveType] = useState([]);
    console.log("LeaveTabNew - leaveType:", leaveType, editLeaveType);
    const [errorMessage, setErrorMessage] = useState(null);
    const [shouldFetch, setShouldFetch] = useState(false);

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, setTabValue, handleShowTabCondition } = useEmployeeContext();

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();

    //validation
    const validationSchema = Yup.object().shape({});

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    //Submit form
    const handleSubmitForm = async (data) => {
        console.log("handleSubmitForm - data:", data);
        const postData = {};
        leaveType.forEach((leave) => {
            postData[leave.id] = leave.value ?? 0;
        });
        postData.employee_id = id ? id : basicEmployeeInfo?.id;

        try {
            const res = await Axios.post(`/employee/leave`, postData, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log("employee res:", res);
            if (res?.data?.status === "success") {
                toast.success("Leave info data saved");
                handleShowTabCondition("attachment");
                // setTabValue((prev) => prev + 1);
                setErrorMessage(null);
            } else if (res.data.success === "200") {
                toast.info("You don't have leave info create  permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log("handleBasicSubmit - error:", error);
            setErrorMessage(error?.response?.data?.errors);
        }
    };

    //get all leave type
    const getAllLeaveType = async () => {
        try {
            const response = await Axios.get(`type-of-leave/all`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            setLeaveType(response.data.response_data);
            setShouldFetch((prev) => !prev);
        } catch (error) {
            console.log(error.response.status);
        } finally {
        }
    };

    const changeHandler = (value, index) => {
        const leaves = [...leaveType];
        if (value.length > 0) {
            leaves[index].value = parseInt(value);
            setLeaveType(leaves);
        } else {
            leaves[index].value = 0;
        }
        console.log(leaves);
        setLeaveType(leaves);
    };

    //get previous data
    const getPreviousData = async () => {
        try {
            const res = await Axios.get(`employee/leaves/show/${id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log("getPreviousData - res:", res);
            if (res.data.response_data && res.data.response_data.length > 0) {
                console.log("tes");

                const tempLeaveType = [...leaveType];

                res.data.response_data.forEach((leave) => {
                    const ind = tempLeaveType.findIndex((item) => parseInt(item.id) === parseInt(leave?.leave_type?.id));
                    console.log("res.data.response_data.forEach - ind:", ind);
                    if (ind > -1) {
                        tempLeaveType[ind].value = parseInt(leave.value);
                    }
                });
                console.log("getPreviousData - tempLeaveType:", tempLeaveType);
                setEditLeaveType(tempLeaveType);
            }
        } catch (error) {
            console.log(error);
        }
    };
    //get call leave
    useEffect(() => {
        getAllLeaveType();
    }, []);

    //Call function get previous data
    useEffect(() => {
        if (id) {
            getPreviousData();
        }
    }, [id, shouldFetch]);

    //Condition Render
    const conditionRender = (leave, index) => {
        return (
            <Grid key={index} item lg={4} sm={6} xs={12}>
                <FormControl size={"small"} fullWidth variant="outlined">
                    <Typography sx={{ textTransform: "capitalize" }}>{leave.name}</Typography>
                    <OutlinedInput
                        sx={{ marginBottom: 2 }}
                        type="number"
                        value={leave?.value ?? 0}
                        placeholder={`Enter ${leave.name}`}
                        onChange={(e) => changeHandler(e.target.value, index)}
                    />
                </FormControl>
            </Grid>
        );
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={2}>
                {id && editLeaveType?.length > 0
                    ? editLeaveType?.map((leave, index) => conditionRender(leave, index))
                    : leaveType?.map((leave, index) => conditionRender(leave, index))}
            </Grid>
            <ErrorMessageShow handleError={errorMessage} />
            <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                <Button variant="contained" type={"submit"}>
                    Save
                </Button>
            </Stack>
        </form>
    );
}

export default LeaveTabNew;
