import {
    Box,
    Divider,
    FormControl,
    Grid,
    OutlinedInput,
    Paper,
    Typography,
    Button,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Stack,
    Collapse,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import moment from "moment";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import ApiErrorMessage from "../../components/error/ApiErrorMessage";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../LoadingPage/LoadingPage";
import useGetAllSalarySheet from "../../hooks/useGetAllSalarySheet";
import { useParams } from "react-router-dom";

//Validation
const validationSchema = Yup.object().shape({});

function SalarySheetEdit() {
    const [storeGenerateInfo, setStoreGenerateInfo] = useState(null);
    const [storeEmployee, setStoreEmployee] = useState([]);
    const [isShowGenerate, setIsShowGenerate] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [shoultFethData, setShoultFethData] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [currentDeductionIndex, setCurrentDeductionIndex] = useState(-1);
    const [storeAllSalaryType, setStoreAllSalaryType] = useState([]);
    const [singlePayrollData, setSinglePayrollData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedSheet, setSelectedSheet] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { token } = useAuth();
    const navigate = useNavigate();

    //react-hook-form
    const { control, handleSubmit, setValue, watch } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    const { fields: additionFields } = useFieldArray({
        control,
        name: "additionSalary",
    });

    const { month, year, id } = useParams();

    //Format all generate data for api
    const formatGenerateData = (data) => {
        const storeData = [];
        Object.keys(data).forEach(function (key, value) {
            storeData.push({
                salary_type_id: parseInt(data[key]),
                value: parseInt(data[key]),
            });
        });
        console.log(storeData);
        // return storeData;
    };

    //Calculate of additions fileds
    const watchedAdditionsFields = watch(
        storeAllSalaryType?.map((item) => {
            if (parseInt(item?.type) === 0) {
                return `additionSalary.${currentIndex}.${item?.name}${item?.id}`;
            }
        })
    );
    const totalAdditionsSum = watchedAdditionsFields.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
    const getCurrentId = storeEmployee?.length > 0 && storeEmployee[currentIndex]?.employee_id;
    const updateObject = storeEmployee?.map((item) =>
        item?.employee_id === getCurrentId
            ? {
                  ...item,
                  additionCalculation: totalAdditionsSum,
              }
            : item
    );

    //Calculate of deductions fileds

    const watchedDeductionsFields = watch(
        storeAllSalaryType?.map((item) => {
            if (parseInt(item?.type) === 1) {
                return `additionSalary.${currentIndex}.${item?.name}${item?.id}`;
            }
        })
    );

    const totalDeductionsSum = watchedDeductionsFields.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

    const getCurrentDeductionsId = storeEmployee?.length > 0 && storeEmployee[currentIndex]?.employee_id;
    const updateDeductioObject = storeEmployee?.map((item) =>
        item?.employee_id === getCurrentDeductionsId
            ? {
                  ...item,
                  deductionCalculation: totalDeductionsSum,
              }
            : item
    );

    //calculate gross salary with is_gross = 1
    const watchedGrossSalary = watch(
        storeAllSalaryType?.map((item) => {
            if (parseInt(item?.is_gross) === 1) {
                return `additionSalary.${currentIndex}.${item?.name}${item?.id}`;
            }
        })
    );

    const totalGrossSalary = watchedGrossSalary.reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
    const getCurrentGrossSalaryId = storeEmployee?.length > 0 && storeEmployee[currentIndex]?.employee_id;
    const updateGrossSalaryObject = storeEmployee?.map((item) =>
        item?.employee_id === getCurrentGrossSalaryId
            ? {
                  ...item,
                  grossSalaryCalculation: totalGrossSalary,
              }
            : item
    );

    //Get all salary type
    const getAllSalaryType = async () => {
        try {
            const res = await Axios.get(
                `/type-of-salary/all`,

                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            if (res.data.status === "success") {
                setStoreAllSalaryType(res?.data?.response_data);
            } else if (res.data.success === "200") {
                toast.info("You don't have   all salary type  permissions, contact admin");
            } else {
                console.log("error", res);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    //Get all salary type
    useEffect(() => {
        getAllSalaryType();
    }, []);

    //additions effect after sum
    useEffect(() => {
        setStoreEmployee(
            storeEmployee?.map((item) =>
                parseFloat(item?.employee_id) === parseFloat(getCurrentId)
                    ? {
                          ...item,
                          additionCalculation: totalAdditionsSum,
                          totalCalculation: totalAdditionsSum ?? 0 - item?.deductionCalculation ?? 0,
                      }
                    : item
            )
        );
    }, [totalAdditionsSum]);

    //deductions effect after sum
    useEffect(() => {
        setStoreEmployee(
            storeEmployee?.map((item) => {
                if (parseFloat(item?.employee_id) === parseFloat(getCurrentDeductionsId)) {
                    return {
                        ...item,
                        deductionCalculation: totalDeductionsSum,
                        totalCalculation: item?.additionCalculation ?? 0 - totalDeductionsSum ?? 0,
                    };
                } else {
                    return item;
                }
            })
        );
    }, [totalDeductionsSum]);

    //Change handle
    const [fieldStates, setFieldStates] = useState([]);

    const handleGenerate = (e, employee, salaryIndex) => {
        console.log({ employee, salaryIndex, value: e.target.value });
        console.log({
            singlePayrollData: singlePayrollData[employee].emp_salary_info_data[salaryIndex],
        });
        const tempSinglePayrollData = [...singlePayrollData];
        tempSinglePayrollData[employee].emp_salary_info_data[salaryIndex].amount = e.target.value;
        const totalAddition = tempSinglePayrollData[employee].emp_salary_info_data.reduce((acc, curr) => {
            if (curr.amount_type === 0 && curr.name !== "Total Bonus Amount") {
                return acc + parseFloat(curr.amount);
            }
            return acc;
        }, 0);

        const totalDeduction = tempSinglePayrollData[employee].emp_salary_info_data.reduce((acc, curr) => {
            if (curr.amount_type === 1) {
                return acc + parseFloat(curr.amount);
            }
            return acc;
        }, 0);

        const totalGrossSalary = tempSinglePayrollData[employee].emp_salary_info_data.reduce((acc, curr) => {
            if (curr.is_gross === 1) {
                return acc + parseFloat(curr.amount);
            }
            return acc;
        }, 0);

        tempSinglePayrollData[employee].grand_total_data = {
            addition_total: totalAddition,
            deduction_total: totalDeduction,
            gross_total: totalGrossSalary,
            grand_total: totalAddition - totalDeduction,
        };
        setSinglePayrollData(tempSinglePayrollData);
    };

    const handleChangeGenerate = (e, employee_id, salary_type_id) => {
        const inputValue = e.target.value;

        console.log("res: ", inputValue, employee_id, salary_type_id);

        setFieldStates((prev) => {
            const updatedFieldStates = [...prev];
            const existingEmployeeIndex = updatedFieldStates.findIndex((emp) => emp.employee_id === employee_id);

            if (existingEmployeeIndex !== -1) {
                // Employee exists, update the salary_info array for the specific salary_type_id
                const existingEmployee = updatedFieldStates[existingEmployeeIndex];
                const existingSalaryIndex = existingEmployee.salary_info.findIndex((info) => info.salary_type_id === salary_type_id);

                if (existingSalaryIndex !== -1) {
                    // Update the existing salary_info for the specific salary_type_id
                    existingEmployee.salary_info[existingSalaryIndex] = {
                        salary_type_id: salary_type_id,
                        amount: inputValue,
                    };
                } else {
                    // Add a new entry to salary_info array for the specific salary_type_id
                    existingEmployee.salary_info.push({
                        salary_type_id: salary_type_id,
                        amount: inputValue,
                    });
                }

                updatedFieldStates[existingEmployeeIndex] = existingEmployee;
            } else {
                // Employee doesn't exist, add a new entry to the state
                updatedFieldStates.push({
                    employee_id: employee_id,
                    salary_info: [
                        {
                            salary_type_id: salary_type_id,
                            amount: inputValue,
                        },
                    ],
                });
            }
            console.log("updatedFieldStates: ", updatedFieldStates);
            return updatedFieldStates;
        });
    };
    console.log("fieldStates: ", fieldStates);

    useEffect(() => {
        onSubmit();
    }, []);

    //Get generate form value
    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            console.log("Next Called");
            const res = await Axios.post(
                `/salary/sheet/edit`,
                {
                    month: month,
                    year: year,
                    salary_sheet_id: id,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res?.data?.status === "success") {
                toast.success("Data Found");
                try {
                    const updatedData = res?.data?.response_data.map((employee) => {
                        const bonusAmount = employee.emp_salary_info_data.find((info) => info?.name === "Bonus")?.amount;

                        return {
                            ...employee,
                            emp_salary_info_data: employee.emp_salary_info_data.map((info) =>
                                info.salary_type_id === 1 || info.salary_type_id === "1" ? { ...info, amount: bonusAmount ?? 0 } : info
                            ),
                        };
                    });

                    updatedData.forEach((employee) => {
                        const totalAddition = employee.emp_salary_info_data.reduce((acc, curr) => {
                            if (curr.amount_type === 0 && curr.name !== "Total Bonus Amount") {
                                return acc + parseFloat(curr.amount);
                            }
                            return acc;
                        }, 0);

                        const totalDeduction = employee.emp_salary_info_data.reduce((acc, curr) => {
                            if (curr.amount_type === 1) {
                                return acc + parseFloat(curr.amount);
                            }
                            return acc;
                        }, 0);

                        const totalGrossSalary = employee.emp_salary_info_data.reduce((acc, curr) => {
                            if (curr.is_gross === 1) {
                                return acc + parseFloat(curr.amount);
                            }
                            return acc;
                        }, 0);

                        employee.grand_total_data = {
                            addition_total: totalAddition,
                            deduction_total: totalDeduction,
                            gross_total: totalGrossSalary,
                            grand_total: totalAddition - totalDeduction,
                        };
                    });
                    console.log("updatedData: ", updatedData);
                    setSinglePayrollData(updatedData);
                } catch (error) {
                    console.error("Error updating data: ", error);
                    setSinglePayrollData(res?.data?.salary_sheets);
                }

                // setSinglePayrollData(updatedData);
                setStoreGenerateInfo({
                    month: month,
                    year: year,
                });
                setIsShowGenerate(true);
                setShoultFethData((prev) => !prev);
            } else if (res.data.success === "200") {
                toast.info("You don't have all generate permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            } else if (res?.data?.status === "error") {
                toast.warn(res?.data?.error[0]);
                setStoreGenerateInfo(null);
            }
        } catch (error) {
            // console.log(error);
            setIsShowGenerate(false);
            setStoreGenerateInfo(null);
            if (error?.response?.status === 422) {
                toast.warn(error?.response?.data?.error[0]);
            } else {
                console.log("error", error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    //Generate all data
    const handleGenerateAll = async (formatValue) => {
        const formattedData = [];

        const postData = singlePayrollData.map((item) => ({
            employee_id: item?.employee_id,
            salary_info: item?.emp_salary_info_data
                .filter((itemInfo) => itemInfo?.name !== "Total Bonus Amount")
                .map((itemInfo) => {
                    return {
                        salary_type_id: parseInt(itemInfo?.salary_type_id),
                        value: parseFloat(itemInfo.amount),
                    };
                }),
        }));

        try {
            const res = await Axios.post(
                `/salary/sheet/update/${id}`,
                {
                    month: month,
                    year: year,
                    employee_salaries: postData,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res?.data?.status === "success") {
                toast.success("Data Updated Successfully");
                setErrorMessage(null);
                navigate("/payroll/all-generate");
            } else if (res.data.success === "200") {
                toast.info("You don't have all generate permissions, contact admin");
            } else if (res?.data?.status === "error") {
                toast.warn(res?.data?.error?.[0]);
                setErrorMessage(res?.response?.data?.error);
            }
        } catch (error) {
            // console.log(error);
            setErrorMessage(error?.response?.data?.error);
            console.log("error", error);
            toast.warn(error?.data?.error?.[0]);
        }

        return;

        for (const item of singlePayrollData) {
            formattedData.push({
                employee_id: item.employee_id,

                // If item.emp_salary_info_data contains data
                salary_info:
                    item?.emp_salary_info_data?.length > 0
                        ? item?.emp_salary_info_data?.map((item) => ({
                              salary_type_id: Number(item?.salary_type_id),
                              value: Number(item.amount),
                          }))
                        : // Else
                          storeAllSalaryType?.map((item) => ({
                              salary_type_id: Number(item?.id),
                              value: 0,
                          })),
            });
        }

        console.log("formattedData: ", formattedData);

        try {
            const res = await Axios.post(
                `/salary/sheet/store/multiple`,
                {
                    month: storeGenerateInfo?.month,
                    year: storeGenerateInfo?.year,
                    employee_salaries: formattedData,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res?.data?.status === "success") {
                toast.success("Data Generate Successfully");
                setErrorMessage(null);
                navigate("/payroll/manage");
            } else if (res.data.success === "200") {
                toast.info("You don't have all generate permissions, contact admin");
            } else if (res?.data?.status === "error") {
                toast.warn(res?.data?.error?.[0]);
                setErrorMessage(res?.response?.data?.error);
            }
        } catch (error) {
            // console.log(error);
            setErrorMessage(error?.response?.data?.error);
            console.log("error", error);
            toast.warn(error?.data?.error?.[0]);
        }
    };

    useEffect(() => {
        setStoreEmployee(
            singlePayrollData?.map((item, index) => {
                return {
                    additionCalculation: null,
                    deductionCalculation: null,
                    totalCalculation: null,
                    employee_id: item?.employee_id,
                };
            })
        );
    }, [shoultFethData]);

    // const { data: salaySheetList, isLoading: sheetLoading, isError: sheetError } = useGetAllSalarySheet();
    // console.log({ salaySheetList });

    return (
        <>
            {isLoading && <LoadingPage />}
            {/* <AllSelectEmployee title={"New Payroll"} handleSubmitForm={onSubmit} /> */}
            {!isLoading && (
                <>
                    <Collapse in={true}>
                        <Paper
                            elevation={2}
                            sx={{
                                py: { xs: 2, md: 3, xl: 4 },
                                px: { xs: 2, md: 3, xl: 5 },
                                width: "100%",
                                mx: "auto",
                            }}
                        >
                            <Stack direction={"row"} justifyContent={"space-between"} flexWrap={"wrap"} gap={1} sx={{ mb: 1 }}>
                                <Typography variant="h5" color="#0F3F62">
                                    All Generate
                                </Typography>
                                {storeGenerateInfo !== null && (
                                    <Typography variant="h6" color="#0F3F62" fontSize={16}>
                                        {moment(storeGenerateInfo?.month, "MM").format("MMMM")}, {storeGenerateInfo?.year}
                                    </Typography>
                                )}
                            </Stack>
                            <Divider />
                            <form onSubmit={handleSubmit(handleGenerateAll)}>
                                <Box>
                                    <TableContainer
                                        sx={{
                                            maxHeight: "70vh",
                                            mt: 2,
                                            "&::-webkit-scrollbar": {
                                                height: 8,
                                            },
                                        }}
                                    >
                                        <Table sx={{ width: "100%", mb: 1 }} size="small">
                                            <TableBody>
                                                {singlePayrollData?.map((item, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell
                                                                sx={{
                                                                    minWidth: 170,
                                                                    position: "sticky",
                                                                    left: 0,
                                                                    background: "#ffffff",
                                                                    zIndex: "9",
                                                                }}
                                                            >
                                                                <Box>
                                                                    <Typography variant="h5" fontSize={16} fontWeight={400} color="#0F3F62">
                                                                        {/* {index+1} */}
                                                                        {item?.employee_name || "N/A"} ({item?.hrm_employee_id ?? 0})
                                                                    </Typography>
                                                                    <Button
                                                                        LinkComponent={"a"}
                                                                        href={`mailto:${item?.employee_email}`}
                                                                        sx={{
                                                                            p: 0,
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            color: "#0F3F62",
                                                                            justifyContent: "start",
                                                                            width: 1,
                                                                            minHeight: 0,
                                                                            textTransform: "lowercase",
                                                                        }}
                                                                    >
                                                                        {item?.employee_email || "N/A"}
                                                                    </Button>
                                                                    <Button
                                                                        LinkComponent={"a"}
                                                                        href={`tel:+880${item?.employee_phone}`}
                                                                        sx={{
                                                                            p: 0,
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            color: "#0F3F62",
                                                                            justifyContent: "start",
                                                                            width: 1,
                                                                            minHeight: 0,
                                                                            textTransform: "lowercase",
                                                                        }}
                                                                    >
                                                                        {item?.employee_phone || "N/A"}
                                                                    </Button>
                                                                </Box>
                                                            </TableCell>

                                                            {item?.emp_salary_info_data?.length > 0 ? (
                                                                item?.emp_salary_info_data?.map((data, rowIndex) => {
                                                                    if (data.amount_type === 0 && data.salary_type_id != 1 && data.salary_type_id) {
                                                                        return (
                                                                            <TableCell key={rowIndex}>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: "200px",
                                                                                    }}
                                                                                >
                                                                                    <Typography>{data?.name}</Typography>
                                                                                    <OutlinedInput
                                                                                        placeholder={data?.name}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            if (e.target.value === "") {
                                                                                                e.target.value = 0;
                                                                                            }
                                                                                            handleGenerate(e, index, rowIndex);
                                                                                        }}
                                                                                        value={data?.amount}
                                                                                    />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                        );
                                                                    }

                                                                    if (data.amount_type === 1 && data.salary_type_id) {
                                                                        return (
                                                                            <TableCell key={rowIndex}>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: "200px",
                                                                                    }}
                                                                                >
                                                                                    <Typography>{data?.name}</Typography>
                                                                                    <OutlinedInput
                                                                                        placeholder={data?.name}
                                                                                        type="number"
                                                                                        inputProps={{ min: 0 }}
                                                                                        onChange={(e) => {
                                                                                            if (e.target.value === "") {
                                                                                                e.target.value = 0;
                                                                                            }
                                                                                            handleGenerate(e, index, rowIndex);
                                                                                        }}
                                                                                        value={data?.amount}
                                                                                    />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                        );
                                                                    }

                                                                    if (data.salary_type_id === 1 || data.salary_type_id === "1") {
                                                                        return (
                                                                            <TableCell key={rowIndex}>
                                                                                <FormControl
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        width: "200px",
                                                                                    }}
                                                                                >
                                                                                    <Typography>{data.name}</Typography>
                                                                                    <OutlinedInput
                                                                                        placeholder="Enter Bonus Amount"
                                                                                        type="number"
                                                                                        onChange={(e) => {
                                                                                            handleGenerate(e, index, rowIndex);
                                                                                        }}
                                                                                        value={data?.amount}
                                                                                    />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                        );
                                                                    }
                                                                })
                                                            ) : (
                                                                <>
                                                                    {storeAllSalaryType?.map((salary, index) => {
                                                                        if (salary.type === 0 && salary.id !== 1) {
                                                                            return (
                                                                                <TableCell key={index + 33}>
                                                                                    <Controller
                                                                                        key={salary.id}
                                                                                        render={({ field }) => (
                                                                                            <FormControl
                                                                                                fullWidth
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    mt: 2,
                                                                                                }}
                                                                                            >
                                                                                                <Typography>{salary?.name}</Typography>
                                                                                                <OutlinedInput
                                                                                                    {...field}
                                                                                                    placeholder={salary?.name}
                                                                                                    type="number"
                                                                                                    onChange={(e) => {
                                                                                                        field.onChange(e.target.value);
                                                                                                        handleChangeGenerate(
                                                                                                            e,
                                                                                                            item?.employee_id,
                                                                                                            salary?.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </FormControl>
                                                                                        )}
                                                                                        name={`additionSalary.${index}.${salary?.name}${salary?.id}`}
                                                                                        control={control}
                                                                                        defaultValue={""}
                                                                                    />
                                                                                </TableCell>
                                                                            );
                                                                        }

                                                                        if (salary.type === 1) {
                                                                            return (
                                                                                <TableCell key={index + 33}>
                                                                                    <Controller
                                                                                        key={salary.id}
                                                                                        render={({ field }) => (
                                                                                            <FormControl
                                                                                                fullWidth
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    mt: 2,
                                                                                                }}
                                                                                            >
                                                                                                <Typography>{salary?.name}</Typography>
                                                                                                <OutlinedInput
                                                                                                    {...field}
                                                                                                    placeholder={salary?.name}
                                                                                                    type="number"
                                                                                                    onChange={(e) => {
                                                                                                        field.onChange(e.target.value);
                                                                                                        handleChangeGenerate(
                                                                                                            e,
                                                                                                            item?.employee_id,
                                                                                                            salary?.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </FormControl>
                                                                                        )}
                                                                                        name={`additionSalary.${index}.${salary?.name}${salary?.id}`}
                                                                                        control={control}
                                                                                        defaultValue={""}
                                                                                    />
                                                                                </TableCell>
                                                                            );
                                                                        }

                                                                        if (salary.type === 0 && salary.id === 1) {
                                                                            return (
                                                                                <TableCell key={index + 33}>
                                                                                    <Controller
                                                                                        key={salary.id}
                                                                                        render={({ field }) => (
                                                                                            <FormControl
                                                                                                fullWidth
                                                                                                variant="outlined"
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    mt: 2,
                                                                                                }}
                                                                                            >
                                                                                                <Typography>{salary?.name}</Typography>
                                                                                                <OutlinedInput
                                                                                                    {...field}
                                                                                                    placeholder={salary?.name}
                                                                                                    type="number"
                                                                                                    onChange={(e) => {
                                                                                                        field.onChange(e.target.value);
                                                                                                        handleChangeGenerate(
                                                                                                            e,
                                                                                                            item?.employee_id,
                                                                                                            salary?.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </FormControl>
                                                                                        )}
                                                                                        name={`additionSalary.${index}.${salary?.name}${salary?.salary_type_id}`}
                                                                                        control={control}
                                                                                        defaultValue={""}
                                                                                    />
                                                                                </TableCell>
                                                                            );
                                                                        }
                                                                    })}
                                                                </>
                                                            )}

                                                            <TableCell
                                                                sx={{
                                                                    minWidth: 180,
                                                                    position: "sticky",
                                                                    right: 0,
                                                                    background: "#ebebeb",
                                                                }}
                                                            >
                                                                {/* {storeEmployee[index]?.additionCalculation
                                                              ? storeEmployee[index]?.additionCalculation.toFixed(2)
                                                              : item?.grand_total_data?.addition_total ?? 0} */}
                                                                <Stack>
                                                                    Gross Salary: {item?.grand_total_data?.gross_total ?? 0}
                                                                    <br />
                                                                    Total Addition: {item?.grand_total_data?.addition_total ?? 0}
                                                                    <br />
                                                                    Total Deduction: {item?.grand_total_data?.deduction_total ?? 0}
                                                                    <br />
                                                                    Net Salary: {item?.grand_total_data?.grand_total ?? 0}
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid container spacing={2}>
                                        <Grid item sm={6} xs={12}>
                                            <ApiErrorMessage handleError={errorMessage} />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Box sx={{ textAlign: "end" }}>
                                        <Button type="submit" variant="contained" sx={{ minWidth: 150, mt: 3 }}>
                                            Generate{" "}
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Paper>
                    </Collapse>
                </>
            )}
            {/* <Paper sx={{ p: 4 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>SL#</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salaySheetList?.response_data?.data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>{row.year}</TableCell>
                    <TableCell>{row.create_by}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => {
                          handleClick(e);
                          setSelectedSheet(row);
                        }}
                      >
                        <SlOptionsVertical fontSize={16} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={onEdit}>Edit</MenuItem>
          </Menu>
        </Paper> */}
        </>
    );
}

export default SalarySheetEdit;
