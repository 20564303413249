import { Button, ButtonGroup, Grid, OutlinedInput, Paper, Typography, FormControl, FormHelperText, InputAdornment, IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAuth } from "../../hooks/useAuth";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
const ChangePasswordTab = () => {
    const [showPass1, setShowPass1] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [showPass3, setShowPass3] = useState(false);

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    const { user, token } = useAuth();

    const userId = user?.id;

    const onSubmit = async (data) => {
        try {
            const res = await Axios.post(
                `/profile/password/update/${userId}`,
                {
                    current_password: data.currentPassword,
                    new_password: data.newPassword,
                    password_confirmation: data.confirmPassword,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            if (res.data.status === "success") {
                toast.success("Password Change Successful");
                reset();
            } else {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Paper elevation={0} sx={{ p: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid spacing={2} container alignItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography color={!!formState.errors?.currentPassword ? red[700] : ""}>Current Passowrd</Typography>
                                    <OutlinedInput
                                        type={!showPass1 ? "password" : "text"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPass1(!showPass1)}
                                                    edge="end"
                                                >
                                                    {!showPass1 ? <MdVisibilityOff /> : <MdVisibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...field}
                                        error={!!formState.errors?.currentPassword}
                                    />
                                    {!!formState.errors?.currentPassword ? (
                                        <FormHelperText error>{errors?.currentPassword?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="currentPassword"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography color={!!formState.errors?.newPassword ? red[700] : ""}>New Password</Typography>
                                    <OutlinedInput
                                        type={!showPass2 ? "password" : "text"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPass2(!showPass2)}
                                                    edge="end"
                                                >
                                                    {!showPass2 ? <MdVisibilityOff /> : <MdVisibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...field}
                                        error={!!formState.errors?.newPassword}
                                    />
                                    {!!formState.errors?.newPassword ? <FormHelperText error>{errors?.newPassword?.message}</FormHelperText> : ""}
                                </FormControl>
                            )}
                            name="newPassword"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography color={!!formState.errors?.confirmPassword ? red[700] : ""}>Confirm Password</Typography>
                                    <OutlinedInput
                                        type={!showPass3 ? "password" : "text"}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPass3(!showPass3)}
                                                    edge="end"
                                                >
                                                    {!showPass3 ? <MdVisibilityOff /> : <MdVisibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...field}
                                        error={!!formState.errors?.confirmPassword}
                                    />
                                    {!!formState.errors?.confirmPassword ? (
                                        <FormHelperText error>{errors?.confirmPassword?.message}</FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                </FormControl>
                            )}
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "end" }}>
                        <ButtonGroup variant="contained">
                            <Button type="submit" sx={{ minWidth: "150px !important" }}>
                                Change
                            </Button>
                            <Button color={"inherit"} sx={{ minWidth: "150px !important" }}>
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default ChangePasswordTab;
