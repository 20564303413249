import { Box, Button, FormControl, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../hooks/useAuth";
import { useEmployeeContext } from "../../../context/employee/EmployeeContext";
import Axios from "../../../api/Axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import ErrorMessageShow from "../../employees/ErrorMessageShow";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import useEmployee from "../../../hooks/useEmployee";

//Validation
const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email Required"),
    password: Yup.string().required("Password is Required"),
    confirmPassword: Yup.string(),
});

function LoginTabNew() {
    const [errorMessage, setErrorMessage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAlt, setShowPasswordAlt] = useState(false);

    //Router dom
    const navigate = useNavigate();
    const { id } = useParams();
    console.log("BasicInfoTabNew - id:", id);

    //Context
    const { token } = useAuth();
    const { basicEmployeeInfo, setTabValue, setBasicEmployeeInfo } = useEmployeeContext();

    const { data: employee } = useEmployee({ id });

    //Submit form
    const handleSubmitForm = async (data) => {
        const { email, password, confirmPassword } = data;

        if (password !== confirmPassword) {
            toast.warn(`Passwords do not match!`);
            return;
        }

        try {
            const res = await Axios.post(
                `/employee/login-info`,
                {
                    user_id: employee?.response_data?.user_id,
                    employee_id: id,
                    email: email,
                    password: password,
                },
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            console.log(res?.data?.employee_id, "employee res:", res);
            if (res?.data?.status === "success") {
                toast.success("Login info  data saved");
                setErrorMessage(null);
                if (!id) {
                    navigate("/employees/manage");
                }
            } else if (res.data.success === "200") {
                toast.info("You don't have employee create custom filed info permissions, contact admin");
                navigate("/unauthorized", { replace: true });
            }
        } catch (error) {
            console.log("handleBasicSubmit - error:", error);
            setErrorMessage(error?.response?.data?.error);
        }
    };

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    //get basic data
    const getBasicData = async () => {
        try {
            const response = await Axios.get(`/employee/basic/show/${id}`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log("getBasicData - response:", response);
            if (response?.data?.status === "success") {
                const { email } = response?.data?.response_data;

                setValue("email", email);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) {
            getBasicData();
        }
    }, [id]);

    return (
        <Box component={"form"} onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container spacing={2}>
                <Grid item lg={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.email ? red[700] : ""}>Email*</Typography>
                                <OutlinedInput {...field} type="email" error={!!formState.errors?.email} placeholder="Enter Email" />
                                {!!formState.errors?.email ? <FormHelperText error>{errors?.email?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="email"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.password ? red[700] : ""}>Password*</Typography>
                                <OutlinedInput
                                    {...field}
                                    size={"small"}
                                    placeholder={"Enter Password"}
                                    type={showPassword ? "text" : "password"}
                                    error={!!formState.errors?.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {!!formState.errors?.password ? <FormHelperText error>{errors?.password?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="password"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.confirmPassword ? red[700] : ""}>Confirm Password*</Typography>
                                <OutlinedInput
                                    {...field}
                                    size={"small"}
                                    placeholder={"Repeat Password"}
                                    type={showPasswordAlt ? "text" : "password"}
                                    error={!!formState.errors?.password}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPasswordAlt(!showPasswordAlt)}
                                                edge="end"
                                            >
                                                {showPasswordAlt ? <AiFillEyeInvisible /> : <AiFillEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {!!formState.errors?.confirmPassword ? <FormHelperText error>{errors?.confirmPassword?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="confirmPassword"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            <ErrorMessageShow handleError={errorMessage} />

            <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                <Button variant="contained" type={"submit"}>
                    Save
                </Button>
            </Stack>
        </Box>
    );
}

export default LoginTabNew;
