import axios from "axios";

const Axios = axios.create({
    baseURL: process.env.REACT_APP_LIVE_API_BASE_URL,
    timeout: 60000,
});

// Add a request interceptor
Axios.interceptors.request.use(
    (config) => {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("token");

        // If a token exists, add it to the headers
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request errors
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    function (response) {
        if (response?.data?.success === "200" && !window?.location?.pathname?.includes("unauthorized")) {
            console.log("yes 200");
        } else {
        }
        return response;
    },
    function (error) {
        if (error.response.status === 403 && !window?.location?.pathname?.includes("unauthorized")) {
            console.log("error 403");

            window.location.href = "/unauthorized";
        }
        if (error.response.status === 401 && !window?.location?.pathname?.includes("login")) {
            console.log(error);
            localStorage.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default Axios;
